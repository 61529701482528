<template>
    <div class="layout-news w-full news_full_wrapp">
        <div>
            <div class="news_block_wrapp">
                <div v-show="newsItemLoading" class="flex justify-center mt-64">
                    <a-spin/>
                </div>
                <div 
                    v-show="!newsItemLoading" 
                    :class="{ mobile: isMobile }">
                    <a-breadcrumb>
                        <a-breadcrumb-item><router-link to="/news">Новости</router-link></a-breadcrumb-item>
                        <a-breadcrumb-item>{{ newsItem.title }}</a-breadcrumb-item>
                    </a-breadcrumb>
                    <div v-if="userHasPermissions" class="mt-2 flex items-center">
                        <a-button type="primary" ghost @click="openEdit()">
                            Редактировать
                        </a-button>
                        <a-button type="danger" :loading="deleteLoader" ghost class="ml-2" @click="newsDelete()">
                            Удалить
                        </a-button>
                    </div>
                    <h1 class="font-normal news_title leading-8 mt-2">
                        <span v-if="newsItem.is_important" class="important">
                            <img src="./assets/img/fire.svg" />
                        </span>
                        {{ newsItem.title }}
                    </h1>
                    <div class="font-normal text-sm opacity-60 mt-3.5">{{ formatDate(newsItem.created_at) }}</div>
                    <div v-if="newsItem.image" class="mt-6 news_full_img" :key="newsItem.id">
                        <img class="rounded-lg lazyload" :data-src="newsItem.image && newsItem.image.path" :alt="newsItem.title">
                    </div>
                    <TextViewer 
                        class="text_body mt-6" 
                        :body="newsItem.content" />
                    <div class="flex items-center justify-center like_actions mt-7">
                        <a-badge :count="taskVote.likes_count" :number-style="{ backgroundColor: '#52c41a' }">
                            <a-button 
                                type="ui" 
                                flaticon 
                                shape="circle"
                                icon="fi-rr-social-network" 
                                :class="{ 'blue_color': myVote === 'like'}" 
                                @click="vote('like')" />
                        </a-badge>
                        <a-badge :count="taskVote.dislikes_count">
                            <a-button 
                                type="ui" 
                                class="ml-2"
                                flaticon 
                                shape="circle"
                                icon="fi-rr-hand" 
                                :class="{ 'text_red': myVote === 'dislike'}"
                                @click="vote('dislike')" />
                        </a-badge>
                    </div>
                </div>
            </div>
        </div>
        <div class="aside_news">
            <div v-show="newsSidebarLoading" class="flex justify-center mt-64 ml-24">
                <a-spin/>
            </div>
            <div v-if="isMobile" class="font-normal text-lg mt-2 mb-2 w-full">Новости</div>
            <a-card v-show="!newsSidebarLoading" v-for="item in limitedNewsItems" :key="item.id" class="mb-4">
                <div v-if="isMobile" class="image-container">
                    <img class="lazyload object-cover h-full w-full" :data-src="item.image.path" alt="">
                </div>
                <div class="content-container flex-col">
                    <div class="opacity-60">{{ formatDate(item.created_at) }}</div>
                    <div class="mb-1 rg_news_title">{{ item.title }}</div>
                    <div class="text-container opacity-60 short_text" v-html="item.short_content"></div>
                    <router-link :to="{ name: 'news-full', params: { id: item.id } }" @click.native="fetchNewsItem" class="text-[#0A70CF]">читать</router-link>
                </div>
            </a-card>
        </div>
        <create-drawer ref="createDrawer"/>
    </div>
</template>

<script>
import TextViewer from '@apps/CKEditor/TextViewer.vue'
import { mapState } from 'vuex'
import CreateDrawer from '@/modules/News/CreateDrawer.vue'
import eventBus from "@/utils/eventBus"

export default {
    components: {
        TextViewer,
        CreateDrawer
    },
    data() {
        return {
            taskVote: {},
            taskVoteLoading: false,
            newsItem: {},
            newsSidebar: [],
            newsItemLoading: false,
            newsSidebarLoading: false,
            deleteLoader: false
        };
    },
    created() {
        this.fetchNewsItem();
        this.getNews();
        
    },
    methods: {
        openEdit() {
            this.$nextTick(() => {
                if(this.$refs.createDrawer)
                    this.$refs.createDrawer.openDrawerEdit(this.newsItem)
            })
        },
        async newsDelete() {
            try {
                this.deleteLoader = true
                await this.$http.post('/table_actions/update_is_active/', [{ id: this.$route.params.id, is_active: false }])
                this.$message.success('Новость удалена')
                this.$router.push({ name: 'news' })
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка удаления')
            } finally {
                this.deleteLoader = false
            }
        },
        async vote(choice) {
            let boolChoice,
                fieldToVote,
                oppositeFieldToVote
            if (choice === 'like') {
                fieldToVote = 'likes_count'
                oppositeFieldToVote = 'dislikes_count'
                boolChoice = true
            } else if (choice === 'dislike') {
                fieldToVote = 'dislikes_count'
                oppositeFieldToVote = 'likes_count'
                boolChoice = false
            }
            const payload = {
                vote: boolChoice
            }

            await this.$http.post(`vote/${this.$route.params.id}/`, payload)
                .then(() => {
                    if(this.taskVote.my_vote !== null) {
                        if(this.taskVote.my_vote === boolChoice) {
                            this.taskVote[fieldToVote] += -1
                            this.taskVote.my_vote = null
                        } else {
                            this.taskVote[oppositeFieldToVote] += -1
                            this.taskVote[fieldToVote] += 1
                            this.taskVote.my_vote = boolChoice
                        }
                    } else {
                        this.taskVote[fieldToVote] += 1
                        this.taskVote.my_vote = boolChoice
                    }
                })
                .catch(error => console.error(error))
        },
        async getVote() {
            try {
                this.taskVoteLoading = true
                const { data } = await this.$http.get(`vote/${this.$route.params.id}/`)
                this.taskVote = data
            } catch(e) {
                console.log(e)
            } finally {
                this.taskVoteLoading = false
            }
        },
        async fetchNewsItem() {
            try {
                this.newsItemLoading = true
                const { data } = await this.$http.get(`/news/news/${this.$route.params.id}/`);
                if(data) {
                    this.newsItem = data;
                    this.getVote()
                    if(!data.viewed) {
                        this.$store.commit('navigation/DECREMENT_MENU_COUNTER', 'news')
                        this.$store.commit('DICRIMENT_PWA_COUNTER', 'news')
                        this.newsItem.viewed = true
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.newsItemLoading = false
            }
        },
        async getNews() {
            try {
                this.newsSidebarLoading = true
                const { data } = await this.$http.get('/news/news/list/', {
                    params: {
                        page: this.page
                    }
                })
                this.newsSidebar = data.results;
            } catch(e) {
                console.log(e)
            } finally {
                this.newsSidebarLoading = false
            }
        },
        formatDate(datetimeString) {
            const date = new Date(datetimeString);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear().toString().slice(-2);
            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;
            const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;
            return formattedDate;
        },
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        userHasPermissions() {
            if(this.user)
                return this.user.is_staff || this.user.is_support
            else
                return null
        },
        limitedNewsItems() {
            const filtered = this.newsSidebar.filter(item => item.id !== this.newsItem.id);
            return filtered.slice(0, 3);
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        myVote() {
            if(this.taskVote.my_vote === null)
                return null
            if(this.taskVote.my_vote)
                return 'like'
            return 'dislike'
        },
    },
    mounted() {
        eventBus.$on('update_full_news', () => {
            this.fetchNewsItem()
        })
    },
    beforeDestroy() {
        eventBus.$off('update_full_news')
    }
};
</script>

<style lang="scss" scoped>
.news_full_img{
    display: flex;
    justify-content: center;
    img{
        opacity: 0;
        max-height: 450px;
        &.lazyloaded{
            opacity: 1;
        }
    }
}
.short_text{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color .3s;
    word-break: break-word;
}
.rg_news_title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color .3s;
    word-break: break-word;
}
.news_title{
    font-size: 24px;
    line-height: 33px;
}
.text_body{
    font-size: 16px;
    line-height: 24px;
    p{
        color: #868686;
    }
    @media (min-width: 992px) {
        font-size: 18px;
        line-height: 24px;
    }
}
.news_block_wrapp{
    max-width: 800px;
    margin: 0px auto;
    &::v-deep{
        .ant-breadcrumb-link{
            color: rgba(0, 0, 0, 0.45);
        }
    }
}
.important{
    max-width: 18px;
    min-width: 18px;
    margin-right: 10px;
    display: inline-block
}
.news_full_wrapp{
    padding: 20px;
}
.aside_news{
    margin-top: 30px;
    @media (min-width: 992px) {
        margin-top: 0px;
    }
}
.layout-news {
    @media (min-width: 992px) {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr 300px;
    }
}
.layout-news-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 15px;
}
.mobile {
    margin-left: 0;
}
.content-container {
    @media (max-width: 1200px) {
        display: grid;
        grid-template-rows: 29px auto 1fr auto;
        margin-top: 12px;
        gap: 4px;
    }
}
.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 198px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 15px;
}
.like_actions{
    &::v-deep{
        .ant-badge-count{
            min-width: 15px;
            height: 15px;
            line-height: 15px;
            top: 4px;
            right: 2px;
            font-size: 10px;
            &.ant-badge-multiple-words{
                padding: 0 4px;
            }
            .ant-scroll-number-only{
                height: 15px;
                p{
                    height: 15px;
                }
            }
        }
        .ant-badge-count{
            font-size: 10px !important;
            min-width: 17px;
            height: 17px;
            padding: 0 6px;
            line-height: 17px;
        }   
    }
}
</style>
